// Sidebar imports
import {
  UilEstate,
  UilClipboardAlt,
  UilUsersAlt,
  UilPackage,
  UilChart,
  UilSignOutAlt,
} from "@iconscout/react-unicons";



// Analytics Cards imports
import { UilUsdSquare, UilMoneyWithdrawal } from "@iconscout/react-unicons";
import { keyboard } from "@testing-library/user-event/dist/keyboard";

// Recent Card Imports
import img1 from "../imgs/afaan.png";
import img2 from "../imgs/img2.png";
import img3 from "../imgs/sanya.png";

// Sidebar Data
export const SidebarData = [
  {
    icon: UilEstate,
    heading: "Dashboard",
    link:"#",
  },
  {
    icon: UilClipboardAlt,
    heading: "Course",
    link:"https://dileazaan.netlify.app/",
  },
  {
    icon: UilUsersAlt,
    heading: "Teachers",
    link:"https://dileazaan.netlify.app/",
  },
  {
    icon: UilPackage,
    heading: 'Quran Roman Urdu',
    link:"https://www.quranromanurdu.com/",
  },
  {
    icon: UilChart,
    heading: 'Help',
    link:"https://www.instagram.com/salah_soul/",
  },
  {
    icon: UilChart,
    heading: 'Instagram',
    link:"https://www.instagram.com/dileazaan/",
  },
];

// Analytics Cards Data
export const cardsData = [
  {
    title: "Syllabus",
    color: {
      backGround: "linear-gradient(180deg, #bb67ff 0%, #c484f3 100%)",
      boxShadow: "0px 10px 20px 0px #e0c6f5",
    },
    barValue: 70,
    value: "⭐",
    png: UilUsdSquare,
    series: [
      {
        name: "Syllabus",
        data: [31, 40, 28, 51, 42, 109, 100],
      },
    ],
  },
  {
    title: "Notes",
    color: {
      backGround: "linear-gradient(180deg, #FF919D 0%, #FC929D 100%)",
      boxShadow: "0px 10px 20px 0px #FDC0C7",
    },
    barValue: 80,
    value: "⭐",
    png: UilMoneyWithdrawal,
    link:"https://qurancliphelper.com/#/helper",
    series: [
      {
        name: "Revenue",
        data: [10, 100, 50, 70, 80, 30, 40],
      },
    ],
  },
  {
    title: "Diary Work",
    color: {
      backGround:
        "linear-gradient(rgb(248, 212, 154) -146.42%, rgb(255 202 113) -46.42%)",
      boxShadow: "0px 10px 20px 0px #F9D59B",
    },
    barValue: 60,
    value: "📑",
    png: UilClipboardAlt,
    series: [
      {
        name: "Expenses",
        data: [10, 25, 15, 30, 12, 15, 20],
      },
    ],
  },
];

// Recent Update Card Data
export const UpdatesData = [
  {
    img: img1,
    name: " Afaan",
    noti: "👋",
    time: "Ramadan course start hogaya hai Alhamdulillah!",
    link: "/profile/sayyad-afaan", // Add the link you want
  },
 
  {
    img: img3,
    name: "Sanya Muhammad",
    noti: "👋",
    time: "Girls ka special course chal raha hai!",
    link: "/profile/sanya-muhammad", // Add the link you want
  },
];
