import React from "react";
import Cards from "../Cards/Cards";
import Table from "../Table/Table";
import About from "../About/About";
import "./MainDash.css";
import { Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";

const MainDash = () => {
  return (
    <div className="MainDash">
      <h1>Welcome 👋</h1>
      <Cards />
      <Table />
   
    </div>
  );
};

export default MainDash;
