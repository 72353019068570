import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css'
import MainDash from './components/MainDash/MainDash';
import RightSide from './components/RigtSide/RightSide';
import Sidebar from './components/Sidebar';
import Pricing from "./pages/Pricing"
import Home from "./pages/Home"
import About from "./pages/About"
import Notes from "./pages/Notes"



function App() {
  return (
   
      <div className="App">
        <div className="AppGlass">
          <Sidebar />
          <MainDash />
          <RightSide />
        </div>
       
      </div>
     
  )
}

export default App;