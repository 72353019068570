import React, { useState } from "react";
import "./Sidebar.css";
import Logo from "../imgs/l2.png";
import { UilSignOutAlt, UilEstate, UilClipboardAlt, UilUsersAlt, UilPackage, UilChart } from "@iconscout/react-unicons";
import { SidebarData } from "../Data/Data";
import { UilBars } from "@iconscout/react-unicons";
import { motion } from "framer-motion";


const Sidebar = () => {
  const [selected, setSelected] = useState(0);
  const [expanded, setExpanded] = useState(true);

  const sidebarVariants = {
    true: {
      left: '0'
    },
    false: {
      left: '-60%'
    }
  };

  return (
    <>
      <div className="bars" style={expanded ? { left: '60%' } : { left: '5%' }} onClick={() => setExpanded(!expanded)}>
        <UilBars />
      </div>
      <motion.div className='sidebar'
        variants={sidebarVariants}
        animate={window.innerWidth <= 768 ? `${expanded}` : ''}
      >
        {/* logo */}
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="menu">
          {SidebarData.map((item, index) => {
            const Icon = item.icon; // Storing the icon component
            return (
              <a
                key={index} // Adding a unique key prop
                href={item.link} // Adding the link based on item.heading
                className={selected === index ? "menuItem active" : "menuItem"}
                onClick={() => setSelected(index)}
              >
                <Icon /> {/* Rendering the icon component */}
                <p>{item.heading}</p>
              </a>
            );
          })}
          {/* signoutIcon */}
          <div className="menuItem" style={{ marginTop: '20px' }}>
            <UilSignOutAlt />
            <p>Powered By Unity⚡</p>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Sidebar;
