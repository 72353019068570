// AboutPage.jsx
import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './About.css'; // Import the external CSS file

const AboutPage = () => {
  const boxStyles = [
    {
      background: "linear-gradient(180deg, #bb67ff 0%, #c484f3 100%)",
      boxShadow: "0px 10px 20px 0px #e0c6f5",
      link: "https://qurancliphelper.com/#/helper",
      text: "Syllabus",
      dataPercentage: 100,
    },
    {
      background: "linear-gradient(180deg, #FF919D 0%, #FC929D 100%)",
      boxShadow: "0px 10px 20px 0px #FDC0C7",
      link: "https://qurancliphelper.com/#/helper",
      text: "Notes",
      dataPercentage: 58,
    },
    {
      background: "linear-gradient(rgb(248, 212, 154) -146.42%, rgb(255 202 113) -46.42%)",
      boxShadow: "0px 10px 20px 0px #F9D59B",
      link: "https://qurancliphelper.com/#/helper",
      text: "Diary Work",
      dataPercentage: 43,
    },
  ];

  return (
    <div className="parentContainer">
      <h1>About Page</h1>

      <div className="Cards">
        {boxStyles.map((style, index) => (
          <a key={index} href={style.link} className="card-link">
            <div className="card-container" style={{ background: style.background, boxShadow: style.boxShadow }}>
              <div className="progress-bar-container">
                <CircularProgressbar
                  value={style.dataPercentage}
                  text={`${style.dataPercentage}%`}
                  styles={buildStyles({
                    textColor: '#fff',
                  })}
                />
              </div>
              <div className="card-content">
                <p>{style.text}</p>
                
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default AboutPage;
