// AboutPage.jsx

import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import './Cards.css';


const AboutPage = () => {
  const boxStyles = [
    {
      background: "linear-gradient(180deg, #bb67ff 0%, #c484f3 100%)",
      boxShadow: "0px 10px 20px 0px #e0c6f5",
      link: "https://notes-dil.netlify.app/",
      text: "Syllabus",
      dataPercentage: 84,
    },
    {
      background: "linear-gradient(180deg, #FF919D 0%, #FC929D 100%)",
      boxShadow: "0px 10px 20px 0px #FDC0C7",
      link: "https://notes-dil.netlify.app/notes.html",
      text: "Notes",
      dataPercentage: 58,
    },
    {
      background: "linear-gradient(rgb(248, 212, 154) -146.42%, rgb(255 202 113) -46.42%)",
      boxShadow: "0px 10px 20px 0px #F9D59B",
      link: "https://notes-dil.netlify.app/diary.html",
      text: "Diary",
      dataPercentage: 43,
    },
  ];

  return (
    <div className="parentContainer">
      <div className="Cards">
        {boxStyles.map((style, index) => (
          <Router key={index}>
            <Link to={style.link} className="card-link">
              <div className="card-container" style={{ background: style.background, boxShadow: style.boxShadow }}>
                <div className="progress-bar-container" style={{ marginTop: '-60px', marginLeft: '-10px' }}>
                  <CircularProgressbar
                    value={style.dataPercentage}
                    text={`${style.dataPercentage}%`}
                    styles={buildStyles({
                      textColor: '#fff',
                    })}
                  />
                </div>
                <div className="card-content">
                  <p>{style.text}</p>
                </div>  
              </div>
            </Link>
          </Router>
        ))}
      </div>
    </div>
  );
};

export default AboutPage;